'use client'
import { usePathname } from "next/navigation";
import React from "react"

const Favicon = () => {
    const pathname = usePathname()

    let icon = pathname.includes('/br') && process.env.NEXT_PUBLIC_NAME === 'marlboro' ?
    `/assets/${process.env.NEXT_PUBLIC_NAME}/favicon-br.ico`:
    `/assets/${process.env.NEXT_PUBLIC_NAME}/favicon.ico`
  return (
    <link
      rel={`icon`}
      type="image/x-icon"
      href={icon}
    />
  )
}

export default Favicon
